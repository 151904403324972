import { css } from 'linaria';
import React from 'react';
import { DropdownMenu } from '@jetshop/ui/DropdownMenu';
import { theme } from '../../../components/Theme';

const menu = css`
  font-size: 0.875rem;
  min-width: 214px;

  [data-flight-dropdown-items] {
    top: calc(100% - 1px);
    background: ${theme.colorScheme.superlightgrey};
    border: 1px solid ${theme.colorScheme.primary};
    border-top: 0;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
    z-index: 999;
    width: 100%;
  }
  [data-flight-dropdown-item] {
    overflow: hidden;
    display: block;
    padding: 0.5em 0.75em;
    border-top: 1px solid ${theme.colorScheme.lightgrey};
    width: 100%;
    text-align: left;
    background: transparent;
    display: flex;
    align-items: center;
    span + * {
      margin-left: auto;
    }
    /* qty text */
    span + span {
      padding-left: 0.5em;
      font-size: 80%;
    }
    :hover,
    :focus {
      background: white;
      color: ${theme.colorScheme.primary};
      outline: none;
    }
  }

  [data-flight-dropdown-button] {
    padding: 10px 40px !important;
    margin: 0 10px 5px 0;
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 214px;
    text-align: left;
    background: ${theme.colorScheme.white};
    border-radius: 0rem !important;
    border: 1px solid #efefef !important;
    align-items: center;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    svg {
      position: absolute;
      right: 1rem;
      top: 1rem;
      path {
        fill: #000000 !important;
      }
    }

    :focus {
      border: 1px solid ${theme.colorScheme.black};
    }

    &[aria-expanded='true'] {
      border-radius: 1rem 1rem 0 0;

      .carot {
        transform: rotate(180deg);
      }
    }
  }
  [data-flight-range-button] {
    padding: 0.5rem 1rem !important;
    margin-top: -5px;
    border: 1px solid #efefef !important;
    width: 214px;
    text-align: left;
    background: ${theme.colorScheme.white};
    font-weight: bold;
    border-radius: 0;
    outline: none;
    display: flex;
    align-items: center;

    svg {
      margin-left: auto;
    }

    :focus {
      border: 1px solid ${theme.colorScheme.lightgrey};
    }

    &[aria-expanded='true'] {
      border-radius: 0;
      border-color: ${theme.colorScheme.primary};

      .carot {
        transform: rotate(180deg);
      }
    }
  }
`;

export function FilterDropdownMenu(props) {
  return <DropdownMenu className={menu} {...props} />;
}
