import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import t from '@jetshop/intl';

import React from 'react';
import { styled } from 'linaria/react';
import ToggleButton from '../../../components/ui/ToggleButton';
import { BooleanFilter } from './BooleanFilters';

export const FilterGroup = styled('div')`
  display: flex;
  flex-wrap: wrap;
  button {
    min-width: 10px !important;
    margin-right: 5px;
    color: #000000 !important;
    background: #ffffff !important;

    .ToggleButton-content {
      padding: 1px 0px 1px 10px;
      min-width: 10px !important;
      font-size: 13px;
      font-weight: normal;
      :hover {
        text-decoration: line-through;
      }
      svg {
        right: 5px;
        position: relative !important;
        margin-left: 10px;
        background: #ffffff !important;
        path {
          fill: #000000;
        }
      }
    }
  }
`;
export const AppliedFilterButton = styled(ToggleButton)``;

export const FilterWrapper = styled('aside')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 0rem;
  margin-top: 0.5rem;
  padding: 0 5px;
`;

export const ClearButton = styled('div')`
  color: #000 !important;
  text-decoration: underline;
  font-size: 13px;
  padding-bottom: 3px;
  margin-left: 5px;
  font-weight: 500;
`;

export function ActiveFilters({ filters }) {
  const { clearAllFilters } = useFilters();

  if (!filters) return null;

  const activeListFilters = filters.filter(listFilter => {
    return listFilter.hasActiveItems || listFilter.value === true;
  });

  if (activeListFilters.length === 0) return null;
  return (
    <FilterWrapper>
      {activeListFilters.map(listFilter => {
        return (
          <FilterGroup key={listFilter.id}>
            {listFilter?.items ? (
              listFilter.items
                .filter(item => item.isActive)
                .map(listFilterItem => (
                  <ActiveListFilterItem
                    item={listFilterItem}
                    listFilter={listFilter}
                    key={listFilterItem.value}
                  />
                ))
            ) : (
              <BooleanFilter
                outlined
                filter={listFilter}
                key={listFilter.text}
              />
            )}
          </FilterGroup>
        );
      })}
      <ClearButton hideArrow secondary type="button" onClick={clearAllFilters}>
        {t('Clear all filters')}
      </ClearButton>
    </FilterWrapper>
  );
}

function ActiveListFilterItem({ item, listFilter }) {
  const { apply } = useListFilter({ filter: listFilter });

  return (
    <AppliedFilterButton
      outlined
      selected
      type="button"
      key={item.value}
      onClick={() => {
        apply({ value: item.value });
      }}
    >
      {listFilter.name}: {item.text}
    </AppliedFilterButton>
  );
}
