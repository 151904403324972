import { Above, Below } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { SortOrders } from './SortOrders';
import { styled } from 'linaria/react';
import React, { Component } from 'react';
import { ActiveFilters } from './ActiveFilters';
import { BooleanFilters } from './BooleanFilters';
import FilterDrawer from './FilterDrawer';
import { ListFilters } from './ListFilters';
import { MultiListFilters } from './MultiListFilters';
import { RangeFilters } from './RangeFilters';
import FilterButton from './Mobile/FilterButton';

const Filters = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const FiltersSectionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  .boolean-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;

const FiltersWrapper = styled('div')`
  padding: 0 5px;
  width: 100%;
`;
const BoolSortWrapper = styled('div')``;

class Filter extends Component {
  render() {
    const { filters } = this.props;
    const sortOrders = this.props?.sortOrders ?? [];
    return (
      <>
        <Above breakpoint="md">
          {filters.length && (
            <>
              <FiltersSectionWrapper className="above-md">
                <FiltersWrapper>
                  <Filters>
                    <ListFilters filters={filters} />
                    <RangeFilters filters={filters} />
                    <BooleanFilters filters={filters} />
                  </Filters>
                  <Filters>
                    <MultiListFilters filters={filters} />
                  </Filters>
                </FiltersWrapper>
                <BoolSortWrapper>
                  <Filters>
                    <SortOrders sortOrders={sortOrders} />
                  </Filters>
                </BoolSortWrapper>
              </FiltersSectionWrapper>
              <ActiveFilters className="above-md" filters={filters} />
            </>
          )}
        </Above>
        <Below breakpoint="md">
          {filters.length && (
            <>
              <DrawerTrigger className="below-md" id="filterDrawer">
                {({ showTarget }) => (
                  <FilterButton showTarget={showTarget} filters={filters} />
                )}
              </DrawerTrigger>

              <DrawerTarget className="below-md" id="filterDrawer">
                {({ hideTarget, isOpen }) => (
                  <Drawer isOpen={isOpen} right>
                    <FilterDrawer
                      close={hideTarget}
                      filters={filters}
                      sortOrders={sortOrders}
                    />
                  </Drawer>
                )}
              </DrawerTarget>
            </>
          )}
        </Below>
      </>
    );
  }
}

export default Filter;
