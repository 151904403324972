import React from 'react';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';

const StyledToggleButton = styled('button')`
  margin: 0 10px 5px 0;
  position: relative;
  display: block;
  min-width: 214px;
  color: #4d4f5c;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  box-sizing: border-box;
  &.fill {
    border-radius: 0;
    font-size: inherit;
    display: block;
  }
  &.selected {
    background: ${theme.colorScheme.mediumgrey};
    color: ${theme.colorScheme.white};
  }
  &.outlined {
    background: ${theme.colorScheme.mediumgrey};
    color: ${theme.colorScheme.white};
    border: 1px solid ${theme.colorScheme.lightgrey};

    .ToggleButton-icon {
      background: ${theme.colorScheme.mediumgrey};
      border-radius: 2rem;
      padding: 0.25em;
      margin-right: -0.25em;
    }
  }
  .ToggleButton-content {
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    grid-gap: 0;
    gap: 0;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    background: transparent;
    border: 0;
    cursor: pointer;
    width: 100%;
  }
  .ToggleButton-icon {
    position: absolute;
    right: 1rem;
    align: center;
    opacity: 1;
    width: 1em;
    height: 1em;
    box-sizing: content-box;
  }
`;
const ToggleButton = ({ selected, children, outlined, fill, ...rest }) => (
  <StyledToggleButton
    hideArrow
    {...rest}
    className={cx(
      selected && 'selected',
      outlined && 'outlined',
      fill && 'fill'
    )}
  >
    <div className="ToggleButton-content">
      <span>{children}</span>
      {selected && <Cross className="ToggleButton-icon" />}
    </div>
  </StyledToggleButton>
);
export default ToggleButton;
